<template>
  <modal name="oppositeBooking" transition="pop-out" class="oppositeBookingPopup--wrapper" @close="hide">
    <div class="oppositeBookingPopup modal">
      <div class="oppositeBooking__table inside-table js-acc-content-network">
        <div>
          <div class="oppositeBooking__table-header">
            <div class="oppositeBooking__table-cell oppositeBooking__table-cell--date">Pax</div>
            <div class="oppositeBooking__table-cell">Flyer Name</div>
            <div class="oppositeBooking__table-cell">Flyer email</div>
            <div class="oppositeBooking__table-cell">Flyer mobile</div>
          </div>
          <div class="oppositeBooking__table-body js-customScroll">
            <vue-scroll :ops="ops">
              <div v-for="insideItem of oppositeBookings"
                   :key="insideItem.emptyLegId"
                   class="oppositeBooking__table-row"
              >
                <div class="oppositeBooking__table-row-inner">
                  <div class="oppositeBooking__table-row-header">
                    <div class="oppositeBooking__table-cell">{{insideItem.numPax}}</div>
                    <div class="oppositeBooking__table-cell">{{insideItem.flyerName}}</div>
                    <div class="oppositeBooking__table-cell">{{insideItem.flyerEmail}}</div>
                    <div class="oppositeBooking__table-cell">{{insideItem.flyerMobile}}</div>
                  </div>
                </div>
              </div>
            </vue-scroll>
          </div>
        </div>
      </div>
      <button type="button" class="modal__close" @click="this.hide">
        <SvgIcon name="close-white"/>
      </button>
    </div>
  </modal>
</template>

<script>
import './OppositeBookingsPopup.styl'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import moment from 'moment'

export default {
  name: 'OppositeBookingsPopup',
  props: ['oppositeBookings'],
  data: () => ({
    ops: {
      rail: {
        size: '3px',
      },
      bar: {
        size: '3px',
        background: 'rgba(0,0,0,0.1)',
      },
    },
  }),
  methods: {
    moment,
    show () {
      this.$modal.show('oppositeBooking')
    },
    hide () {
      this.$modal.hide('oppositeBooking')
      this.$emit('clearInfo')
    },
  },
  created () {
    this.$parent.$on('showModal', this.show)
  },
  components: {
    SvgIcon,
  },
}

</script>
