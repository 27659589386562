<template>
  <div class="requestEmptyDashboard">
    <div class="requestEmptyDashboard__header">
      <div>
        <button name='request' class="requestEmptyDashboard__nav-item js-tabNavItem" @click="tabActivate">
          <span class="text">Waiting for match up</span>
        </button>
        <button name='opposite' class="requestEmptyDashboard__nav-item js-tabNavItem" @click="tabActivate">
          <span class="text">Match up</span>
        </button>
      </div>
      <button class="export-button">
        <span
          @click="exportToExcel()"
          class="text">Export</span>
      </button>
    </div>
    <div class="requestEmptyDashboard__content">
      <div class="requestEmptyDashboard__content-item js-tabContentItem">
        <RequestEmptyTable :pageCount="requestEmptyPageCount"
                           :rowCount="requestEmptyCount"
                           @pageClick="onRequestEmptyPageClick"
                           :dataList="requestEmptyList"
        />
      </div>
      <div class="requestEmptyDashboard__content-item js-tabContentItem">
        <RequestEmptyTable :pageCount="oppositeBookingPageCount"
                           :rowCount="oppositeBookingCount"
                           @pageClick="onOppositeBookingPageClick"
                           :dataList="oppositeBookingList"
                           :isMatch="true" />
      </div>
    </div>
  </div>
</template>

<script>
import './RequestEmpty.styl'
import RequestEmptyTable from '@/components/dashboard/RequestEmptyTable/RequestEmptyTable'
import {Tabs} from '@/js/plugins/tabs';

export default {
  name: 'RequestEmpty',
  data: () => ({
    ops: {
      rail: {
        size: '3px',
      },
      bar: {
        size: '3px',
        background: 'rgba(0,0,0,0.1)',
      },
    },
    tabs: new Tabs('.js-tabNavItem', '.js-tabContentItem'),
    tabSelected: 'request',
  }),
  computed: {
    requestEmptyList () {
      return this.$store.state.requestEmpty.requestEmptyList
    },
    requestEmptyPageCount () {
      return this.$store.state.requestEmpty.requestEmptyPageCount
    },
    requestEmptyCount () {
      return this.$store.state.requestEmpty.requestEmptyCount
    },
    oppositeBookingList () {
      return this.$store.state.requestEmpty.oppositeBookingList
    },
    oppositeBookingCount () {
      return this.$store.state.requestEmpty.oppositeBookingCount
    },
    oppositeBookingPageCount () {
      return this.$store.state.requestEmpty.oppositeBookingPageCount
    },
  },
  mounted () {
    this.tabs.addActiveForMouted()
  },
  methods: {
    tabActivate (event) {
      this.tabs.addActiveForClick(event.currentTarget)
      this.tabSelected = event.currentTarget.name
    },
    async onRequestEmptyPageClick (data) {
      await this.$store.dispatch('getRequestEmpty', data);
    },
    async onOppositeBookingPageClick (data) {
      await this.$store.dispatch('getOppositeBooking', data);
    },
    async exportToExcel () {
      try {
        const isMatch = this.tabSelected === 'opposite'
        const {data} = await this.$store.dispatch('exportRequestEmpty', isMatch);

        const downloadLink = document.createElement('a');
        const blob = new Blob(['\ufeff', data]);
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = `request_empty_${this.tabSelected}_report_` + new Date().toLocaleDateString().replaceAll('/', '-') + '.csv';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } catch (e) {
        console.log(e)
      }
    },
  },
  components: {
    RequestEmptyTable,
  },
}
</script>
