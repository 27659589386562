<template>
  <div class="requestEmptyDashboard__table">
    <Pagination
      :pageCount="pageCount"
      :rowCount="rowCount"
      @pageClick="onPageClick"
    />
    <div class="requestEmptyDashboard__table-header">
      <div class="requestEmptyDashboard__table-cell">№</div>
      <div class="requestEmptyDashboard__table-cell">Flyer</div>
      <div class="requestEmptyDashboard__table-cell">Departure</div>
      <div class="requestEmptyDashboard__table-cell">Arrival</div>
      <div class="requestEmptyDashboard__table-cell requestEmptyDashboard__table-cell--date">Date</div>
      <div class="requestEmptyDashboard__table-cell requestEmptyDashboard__table-cell--price">TotalPrice</div>
      <div class="requestEmptyDashboard__table-cell" v-if="isMatch">OppositeBooking</div>
      <div class="requestEmptyDashboard__table-cell requestEmptyDashboard__table-cell-button" v-if="!isMatch"></div>
    </div>
    <div class="requestEmptyDashboard__table-body js-customScroll">
      <vue-scroll :ops="ops">
        <div
          v-for="item of dataList"
          :key="item.id"
          class="requestEmptyDashboard__table-row"
          :class="{'mutation': item.id === activeList.id}"
        >
          <div
            class="requestEmptyDashboard__table-row-inner js-acc-hover-item"
          >
            <div class="requestEmptyDashboard__table-row-header">
              <div class="requestEmptyDashboard__table-cell">
                <div class="requestEmptyDashboard__table-index">
                  <a class="requestEmptyDashboard__table-name" to=""
                     @click="(e) => showPlaneInfo(e, item.aircraftId)"
                  >{{item.tailNumber}}</a>
                  </div>
              </div>
              <div class="requestEmptyDashboard__table-cell">
                <a
                  @click="openAdditionalDetails(item)"
                  class="networkDashboard__table-name">
                {{item.flyerName}}
                </a>
              </div>
              <div class="requestEmptyDashboard__table-cell">{{item.departureName}}</div>
              <div class="requestEmptyDashboard__table-cell">{{item.arrivalName}}</div>
              <div class="requestEmptyDashboard__table-cell requestEmptyDashboard__table-cell--date">{{moment(item.departureDate).format('M/D/YYYY')}}</div>
              <div class="requestEmptyDashboard__table-cell requestEmptyDashboard__table-cell--price">{{item.exclusiveTotalPrice.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}}</div>
              <div class="requestEmptyDashboard__table-cell" v-if="isMatch">
                <a
                  @click="showOppositeBooking(item)"
                  class="networkDashboard__table-name" v-if="item.oppositeBookings.length">
                  {{ 'Show' }}
                </a>
              </div>
              <div class="requestEmptyDashboard__table-cell requestEmptyDashboard__table-cell-button" v-if="!isMatch">
                <button @click="() => showConfirmationPopup(item.id)" class="delete-button">
                  <span class="text">
                    Cancel
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </vue-scroll>
    </div>
    <ConfirmPopup :text="'Are you sure you want to cancel?'" :title="'Are you sure?'" @confirm="deleteRequest"/>
    <FlyerShortInfoPopup :flyerInfo="flyerInfo" @clearInfo="clearInfo"/>
    <ShortPlaneDetailsPopup :planeInfo="planeInfo" @clearInfo="clearInfo"/>
    <OppositeBookingsPopup :oppositeBookings="activeList.oppositeBookings" @clearInfo="clearInfo"/>
  </div>
</template>

<script>
import moment from 'moment'
import Pagination from '@/components/common/Pagination/Pagination'
import FlyerShortInfoPopup from '@/components/common/modals/FlyerShortInfoPopup.vue';
import ShortPlaneDetailsPopup from '@/components/common/modals/ShortPlaneDetailsPopup.vue';
import OppositeBookingsPopup from '@/components/common/modals/OppositeBookingsPopup.vue';
import {aircraftsAPI} from '@/api/aircrafts';
import ConfirmPopup from '@/components/common/modals/ConfirmPopop.vue';

export default {
  props: ['pageCount', 'rowCount', 'dataList', 'isMatch'],
  name: 'RequestEmptyTable',
  data: () => ({
    ops: {
      rail: {
        size: '3px',
      },
      bar: {
        size: '3px',
        background: 'rgba(0,0,0,0.1)',
      },
    },
    flyerInfo: {},
    activeList: {},
    planeInfo: {},
    requestId: null,
    isLoading: false,
  }),
  methods: {
    moment,
    async openAdditionalDetails (info) {
      const { flyerId, flyerName, flyerEmail, flyerMobile } = info

      this.flyerInfo = {
        flyerId,
        flyerName,
        flyerEmail,
        flyerMobile,
      };

      this.$modal.show('FlyerShortInfoPopup')
    },
    onPageClick (data) {
      this.$emit('pageClick', data);
    },
    showConfirmationPopup (id) {
      this.$modal.show('confirmPopup')
      this.requestId = id
    },
    async deleteRequest () {
      this.isLoading = true
      await this.$store.dispatch('cancelRequestEmpty', this.requestId);

      if (this.isMatch) {
        await this.$store.dispatch('getOppositeBooking', {pageNumber: this.pageCount, pageSize: this.rowCount});
      } else {
        await this.$store.dispatch('getRequestEmpty', {pageNumber: this.pageCount, pageSize: this.rowCount});
      }

      this.$modal.hide('confirmPopup')
      this.isLoading = false
    },
    showOppositeBooking (item) {
      this.activeList = item
      this.$modal.show('oppositeBooking')
    },
    async showPlaneInfo (e, id) {
      e.stopPropagation()
      const {data} = await aircraftsAPI.getShortPlaneInfo(id)
      this.planeInfo = data.item.value

      this.$modal.show('ShortPlaneDetailsPopup')
    },
    clearInfo () {
      this.flyerInfo = {}
      this.activeList = {}
      this.planeInfo = {}
    },
  },
  components: {
    ConfirmPopup,
    ShortPlaneDetailsPopup,
    Pagination,
    FlyerShortInfoPopup,
    OppositeBookingsPopup,
  },
}
</script>
